module.exports = {
  siteTitle: 'Glenn O\'Grady Site', // <title>
  manifestName: 'Glenn O\'Grady',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-spectral/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Glenn O\'Grady',
  subHeading: 'Business advisor, Non-Executive Director and Investor',
  author: 'Glenn O\'Grady',
  ga: "UA-196743618-1",
  socialLinks: [
    {
      style: 'brands',
      icon: 'fa-linkedin',
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/glennogrady/',
    },
  ]
};
